import React from 'react';
import Swiper from 'react-id-swiper';
import { Container, List, TextInput, Img, SelectMenu } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';

const Card = ({ sm, title, link, image, description }) => (
  <div className="card">
    {link ?
      <a href={link} className="image">
        <Img src={image} variant="square" className="image-desktop" />
        <Img src={image} variant="card_horizontal" className="image-mobile" />
      </a>
      :
      (
        <div className="image">
          <Img src={image} variant="square" className="image-desktop" />
          <Img src={image} variant="card_horizontal" className="image-mobile" />
        </div>
      )
    }

    {sm ?
      <h4 className="title">{link ? <a href={link}>{title}</a> : title}</h4>
      :
      <h3 className="title">{link ? <a href={link}>{title}</a> : title}</h3>
    }

    {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
  </div>
);

const CardsCarouselWidget = ({ list, autoplay, containerSettings }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const swiperOptions = autoplay === 'yes' ? { autoplay: { delay: 3000 } } : {};

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container settings={containerSettings}>
      <div className="cards cards-carousel">
        <div className="container">
          <Swiper
            getSwiper={updateSwiper}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
            }}
            breakpoints={{
              939: {
                slidesPerView: 1,
                spaceBetween: 30,
                paginationClickable: true,
                slidesPerGroup: 1,
              },
              940: {
                slidesPerView: 4,
                spaceBetween: 30,
                paginationClickable: true,
                slidesPerGroup: 1,
              }
            }}
            {...swiperOptions}
          >
            {list.map((card) =>
              <div className="slide" key={card.id}>
                <Card
                  sm
                  title={card.title}
                  link={card.link}
                  image={card.image}
                  description={card.description}
                />
              </div>
            )}
          </Swiper>

          <button onClick={goNext} className="swiper-button-next icon-arrow-right" />
          <button onClick={goPrev} className="swiper-button-prev icon-arrow-left" />
        </div>
      </div>
    </Container >
  );
};

CardsCarouselWidget.title = 'Cards Carousel';
CardsCarouselWidget.category = 'Media';
CardsCarouselWidget.dialogSize = 'large';

CardsCarouselWidget.defaultProps = {
  list: [],
  autoplay: 'no'
};

CardsCarouselWidget.defaults = {
  list: [
    { id: 1, title: 'Card title 1', link: '#', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' },
    { id: 2, title: 'Card title 2', link: '', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' },
    { id: 3, title: 'Card title 3', link: '#', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' },
    { id: 4, title: 'Card title 4', link: '#', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' },
    { id: 5, title: 'Card title 5', link: '#', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' },
  ],
  variant: '3_columns',
  autoplay: 'no',
  containerSettings: {},
};

const CardsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

CardsListItem.defaultProps = {
  settings: {},
};

CardsCarouselWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Autoplay"
      options={[['yes', 'Yes'], ['no', 'No']]}
      value={settings.autoplay}
      onChange={value => onChange('autoplay', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <CardsListItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{ title: 'Card title 1', link: '#', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', image: 'http://placehold.it/540x540' }}
    />
  </div>
);

export default CardsCarouselWidget;
