import React from 'react';
import Swiper from 'react-id-swiper';
import { Container, List, TextInput, Img } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';

const Item = ({ image, description, author }) => (
  <div className="reference">
    <div className="row">
      <div className="shift-2">
        <Img src={image} variant="square" className="avatar" />

        <div className="description" dangerouslySetInnerHTML={{ __html: description }} />

        <strong>{author}</strong>
      </div>
    </div>
  </div>
);

const ReferenceSliderWidget = ({ list, containerSettings }) => {
  const [swiper, updateSwiper] = React.useState(null);

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container settings={containerSettings}>
      <div className="reference-sliders">
        <div className="container">
          <Swiper
            getSwiper={updateSwiper}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
            }}
            slidesPerGroup={1}
            slidesPerView={1}
            spaceBetween={0}
            paginationClickable
          >
            {list.map((item) => (
              <div className="slide" key={item.id}>
                <div key={item.id}>
                  <Item {...item} />
                </div>
              </div>
            ))}
          </Swiper>

          <button onClick={goNext} className="swiper-button-next icon-arrow-right" />
          <button onClick={goPrev} className="swiper-button-prev icon-arrow-left" />
        </div>
      </div>
    </Container>
  );
}

ReferenceSliderWidget.title = 'Reference slider';
ReferenceSliderWidget.category = 'Media';
ReferenceSliderWidget.dialogSize = 'large';

ReferenceSliderWidget.defaults = {
  list: [
    { id: 1, image: 'http://placehold.it/540x540', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', author: 'John Doe, Director at ACME Inc.' },
    { id: 2, image: 'http://placehold.it/540x540', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', author: 'John Doe, Director at ACME Inc.' },
    { id: 3, image: 'http://placehold.it/540x540', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', author: 'John Doe, Director at ACME Inc.' },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />

    <TextInput
      label="Author"
      onChange={value => onChangeItem('author', value)}
      value={settings.author}
    />
  </div>
);

ReferenceSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <ListItem settings={settings} onChangeItem={onChangeItem} />}
      items={items}
      template={{ image: 'http://placehold.it/540x540', description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>', author: 'John Doe, Director at ACME Inc.' }}
      primaryField="author"
      onChangeList={onChange}
    />
  </div>
);

export default ReferenceSliderWidget;
