import React from 'react';
import { LanguagesProvider } from './providers/LanguagesContext';
import Layout from './frontend/Layout';
import LIBRARY from './widgets';

const shouldRenderLayout = (page) => (page.slug !== 'contacts-modal');

const PageRender = ({ page, page_context }) => {
  const hasLayout = shouldRenderLayout(page);
  const LayoutComponent = hasLayout ? Layout : React.Fragment;

  return (
    <div className={`${hasLayout ? 'header-padding' : ''}`}>
      <LayoutComponent page_context={page_context}>
        <LanguagesProvider languages={page_context.languages}>
          {page.widgets.map((widget, idx) =>
            React.createElement(
              LIBRARY[widget.component],
              Object.assign({}, { key: idx }, widget.settings)))}
        </LanguagesProvider>
      </LayoutComponent>
    </div>
  );
};

export default PageRender;
