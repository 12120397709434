import React from 'react';

class TableBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table: props.value,
    };
  }

  handleAddCol = () => {
    const { table } = this.state;
    const newTable = table.map(row => [...row, '']);

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleRemoveCol = (cidx) => {
    const { table } = this.state;
    const newTable = table.map((row) => [
      ...row.slice(0, cidx),
      ...row.slice(cidx + 1),
    ]);

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleAddRow = () => {
    const { table } = this.state;
    const firstRow = table[0];

    const newRow = Array.from(Array(firstRow.length).keys()).map(_ => '');

    const newTable = [
      ...table,
      newRow
    ];

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleRemoveRow = (ridx) => {
    const { table } = this.state;
    const newTable = [
      ...table.slice(0, ridx),
      ...table.slice(ridx + 1),
    ];

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  handleChangeCell(value, ridx, cidx) {
    const { table } = this.state;
    const newTable = table.map((row, rridx) => {
      if (ridx !== rridx) return row;

      return [
        ...row.slice(0, cidx),
        value,
        ...row.slice(cidx + 1),
      ];
    });

    this.setState({ table: newTable }, () => this.notifyChange());
  }

  notifyChange() {
    const { table } = this.state;
    if (this.props.onChange) this.props.onChange(table);
  }

  render() {
    const { table } = this.state;
    const firstRow = table[0];

    return (
      <div className="table-builder">
        <table>
          <tbody>
            {table.map((row, ridx) => (
              <tr key={ridx}>
                {row.map((col, cidx) => (
                  <td key={cidx}><input type="text" value={col} onChange={e => this.handleChangeCell(e.target.value, ridx, cidx)} /></td>
                ))}
                <td style={{ width: '50px' }}>
                  {(ridx === 0) &&
                    <button type="button" onClick={this.handleAddCol} className="btn-primary sm">+</button>
                  }

                  {(ridx > 0) &&
                    <button type="button" onClick={() => this.handleRemoveRow(ridx)} className="btn-secondary sm">&times;</button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {firstRow.map((_, cidx) => (
                <td key={cidx}>
                  <button type="button" onClick={() => this.handleRemoveCol(cidx)} className="btn-secondary sm">&times;</button>
                </td>
              ))}
              <td>&nbsp;</td>
            </tr>
          </tfoot>
        </table>

        <button type="button" onClick={this.handleAddRow} className="btn-primary sm">Add row</button>
      </div>
    );
  }
}

TableBuilder.defaultProps = {
  value: [['Col 1', 'Col 2', 'Col 3']],
};

export default TableBuilder;
