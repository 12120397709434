import React from 'react';

const Switch = ({ label, url, active }) => (
  <a href={url} className={active ? 'active' : ''}>
    {label}
  </a>
);

const LanguageSwitcher = ({ current, all }) => (
  <div className="language-switcher">
    {all.map((language, idx) => <Switch key={idx} label={language.label} url={language.url} active={current === language.label} />)}
  </div>
);

export default LanguageSwitcher;
