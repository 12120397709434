import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "./Input";
import Textarea from "./Textarea";
import post from "../../utils/http";

const FORM_URL = "https://forms.raketa.cloud/submit/5Dy3XcwjCD";

class ContactFormWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      phone: "",
      company: "",
      fileLink: "",
      sent: false,
      errored: false,
      errors: {},
    };

    this.recaptchaChangedHandler = this.recaptchaChangedHandler.bind(this);
  }

  handleUpdate(field, value) {
    this.setState({ [field]: value });
  }

  collectErrors() {
    const {
      nameErrorMessage,
      emailErrorMessage,
      phoneErrorMessage,
      messageErrorMessage,
    } = this.props;
    const { name, email, phone, message } = this.state;
    const errors = {};

    if (name.trim() === "") errors["name"] = nameErrorMessage;
    if (email.trim() === "") errors["email"] = emailErrorMessage;
    if (phone.trim() === "") errors["phone"] = phoneErrorMessage;
    if (message.trim() === "") errors["message"] = messageErrorMessage;

    return errors;
  }

  handleSubmit(e) {
    e.preventDefault();
    const errors = this.collectErrors();

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.captcha.execute();
    }
  }

  recaptchaChangedHandler(recaptchaValue) {
    const { name, email, phone, company, fileLink, message } = this.state;
    const captcha_value = recaptchaValue;
    const file_link = fileLink;

    const params = {
      data: {
        name,
        email,
        phone,
        company,
        file_link,
        message,
      },
      "g-recaptcha-response": captcha_value,
    };

    post(FORM_URL, params)
      .then((resp) => {
        if (resp.ok) {
          this.setState({ sent: true });
        } else {
          this.setState({ sent: true, errored: true });
        }
      })
      .catch((error) => {
        this.setState({ sent: true, errored: true });
      });
  }

  render() {
    const {
      errorMessage,
      successMessage,

      nameLabel,
      namePlaceholder,

      emailLabel,
      emailPlaceholder,

      messageLabel,
      messagePlaceholder,

      phoneLabel,
      phonePlaceholder,

      companyLabel,
      companyPlaceholder,

      fileLinkLabel,
      fileLinkPlaceholder,
      fileLinkHint,

      buttonLabel,
      containerSettings,

      captchaPublicKey,
    } = this.props;
    const {
      errored,
      sent,
      name,
      email,
      phone,
      company,
      fileLink,
      message,
      errors,
    } = this.state;

    return (
      <Container settings={containerSettings}>
        <div className="container">
          {sent && errored && (
            <div className="page-title">
              <h2 className="title">{errorMessage}</h2>
            </div>
          )}
          {sent && !errored && (
            <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          )}
          {!sent && (
            <div className="row">
              <div className="col-6">
                <div className="contact-form">
                  <form
                    action={FORM_URL}
                    method="post"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <div className="form-row">
                      <Input
                        type="text"
                        label={nameLabel}
                        name="data[name]"
                        error={"name" in errors ? errors["name"] : false}
                        value={name}
                        placeholder={namePlaceholder}
                        onChange={(value) => this.handleUpdate("name", value)}
                      />

                      <Input
                        type="email"
                        label={emailLabel}
                        name="data[email]"
                        error={"email" in errors ? errors["email"] : false}
                        value={email}
                        placeholder={emailPlaceholder}
                        onChange={(value) => this.handleUpdate("email", value)}
                      />
                    </div>

                    <div className="form-row">
                      <Input
                        type="tel"
                        label={phoneLabel}
                        name="data[phone]"
                        error={"phone" in errors ? errors["phone"] : false}
                        value={phone}
                        placeholder={phonePlaceholder}
                        onChange={(value) => this.handleUpdate("phone", value)}
                      />

                      <Input
                        type="text"
                        label={companyLabel}
                        name="data[company]"
                        error={"company" in errors ? errors["company"] : false}
                        value={company}
                        placeholder={companyPlaceholder}
                        onChange={(value) =>
                          this.handleUpdate("company", value)
                        }
                      />
                    </div>

                    <div className="contact-files">
                      <Input
                        type="text"
                        label={fileLinkLabel}
                        name="data[fileLink]"
                        hint={fileLinkHint}
                        error={
                          "fileLink" in errors ? errors["fileLink"] : false
                        }
                        value={fileLink}
                        placeholder={fileLinkPlaceholder}
                        onChange={(value) =>
                          this.handleUpdate("fileLink", value)
                        }
                      />
                    </div>

                    <Textarea
                      label={messageLabel}
                      name="data[message]"
                      error={"message" in errors ? errors["message"] : false}
                      value={message}
                      onChange={(value) => this.handleUpdate("message", value)}
                      placeholder={messagePlaceholder}
                    />

                    <ReCAPTCHA
                      ref={(el) => {
                        this.captcha = el;
                      }}
                      size="invisible"
                      sitekey={captchaPublicKey}
                      onChange={this.recaptchaChangedHandler}
                    />

                    <button type="submit" className="btn-primary ">
                      {buttonLabel}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

ContactFormWidget.title = "Contact form";
ContactFormWidget.category = "Highlights";
ContactFormWidget.dialogSize = "large";

ContactFormWidget.defaults = {
  nameLabel: "Your name *",
  emailLabel: "Your e-mail *",
  messageLabel: "Your message *",
  phoneLabel: "Your phone *",
  companyLabel: "Your company",
  fileLinkLabel: "Link to a file",
  buttonLabel: "Submit message",

  successMessage: "Thank you!",
  errorMessage: "Something went wrong, please try again later!",
  nameErrorMessage: "can not be blank",
  emailErrorMessage: "can not be blank",
  phoneErrorMessage: "can not be blank",
  messageErrorMessage: "can not be blank",

  namePlaceholder: "Enter your name",
  emailPlaceholder: "Enter your email",
  messagePlaceholder: "Enter your question",
  phonePlaceholder: "Enter your phone",
  companyPlaceholder: "Enter your company",
  fileLinkPlaceholder: "Enter a link to a file",

  fileLinkHint:
    'Моля, изпратете ни линк към вашите файлове, които могат да бъдат качени в <a href="https://www.dropbox.com/" target="blank">Dropbox</a>, <a href="https://www.google.com/drive/" target="blank">Google Drive</a>, <a href="https://dox.abv.bg/" target="blank">Dox.bg</a> или <a href="https://wetransfer.com/" target="blank">WeTransfer</a>',

  captchaPublicKey: "6LdsqXUUAAAAALNEYVheQzjwKRXEejO1Mk5jwijQ",

  containerSettings: {},
};

ContactFormWidget.adminFields = {
  nameLabel: { type: "text" },
  emailLabel: { type: "text" },
  messageLabel: { type: "text" },
  phoneLabel: { type: "text" },
  companyLabel: { type: "text" },
  fileLinkLabel: { type: "text" },
  buttonLabel: { type: "text" },
  successMessage: { type: "custom", component: RichText },
  errorMessage: { type: "custom", component: RichText },
  nameErrorMessage: { type: "text" },
  emailErrorMessage: { type: "text" },
  phoneErrorMessage: { type: "text" },
  messageErrorMessage: { type: "text" },
  namePlaceholder: { type: "text" },
  emailPlaceholder: { type: "text" },
  messagePlaceholder: { type: "text" },
  phonePlaceholder: { type: "text" },
  companyPlaceholder: { type: "text" },
  fileLinkPlaceholder: { type: "text" },
  fileLinkHint: { type: "text" },
  captchaPublicKey: { type: "text" },
};

export default ContactFormWidget;
