import React from 'react';
import { Container, Img } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';

const TestimonialWidget = ({ description, author, position, avatar, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="testimonial">
        <div className="row">
          <div className="col-8">
            <div className="content">
              {description && <blockquote dangerouslySetInnerHTML={{ __html: description }} />}

              <div className="author">
                {(avatar && avatar.name) && <Img src={avatar} variant="thumb" />}
                <strong>{author}</strong>, {position}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

TestimonialWidget.title = 'Testimonial';
TestimonialWidget.category = 'Highlights';

TestimonialWidget.defaultProps = {
  description: '',
  avatar: {},
};

TestimonialWidget.defaults = {
  description: '<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!</p>',
  author: 'John Doe',
  position: 'CEO',
  avatar: 'http://placehold.it/50x50',
  containerSettings: {},
};

TestimonialWidget.adminFields = {
  description: { type: 'custom', component: RichText },
  author: { type: 'text' },
  position: { type: 'text' },
  avatar: { type: 'custom', component: ImagePicker },
};

export default TestimonialWidget;