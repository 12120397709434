import React from 'react';
import Modal from './Modal';

const KEY = 'cpc-notice-read';

export default () => {
  const [open, setOpen] = React.useState(localStorage.getItem(KEY) || '1');
  const closeModal = () => {
    localStorage.setItem(KEY, '0');
    setOpen(false);
  }

  return (
    <Modal isOpen={open === '1'} title="Важна информация" onClose={() => closeModal()}>
      <div className="modal-wrapper" style={{ height: 'auto' }}>
        <div className="modal-body">
          <i className="alert-icon" />

          <p>Във връзка с обявеното извънредно положение заради увеличаващите се случаи на Корона вурус в България, копирен център КОПИКОМ БЪЛГАРИЯ ЕООД въвежда временен график на работното време с клиенти: </p>

          <ul>
            <li><strong>Понеделник - Петък</strong> - 07:00-22:00ч</li>
            <li><strong>Събота</strong> - 09:00-21:00ч.</li>
            <li><strong>Неделя</strong> - 09:00-20:00ч.</li>
          </ul>

          <p>Временният график ще е в сила до отмяната на Извънредно положение в страната. Уверяваме ви, че ще спазим всички мерки, за да осигурим безопасност на работа, както за нашия екип така и за всички клиенти. Няма да допускаме повече от трима човека във фронт офиса, за да ограничим струпването на хора.</p>

          <p>Призоваваме всички да бъдат социално отговорни, като ограничат максимално контактите си с други хора в следващите седмици, за да може да се справим по-бързо със създалата се ситуация! </p>

          <div className="text-center">
            <button type="button" className="btn-secondary" onClick={() => closeModal()}>Затвори</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
