import React from 'react';

const LanguagesContext = React.createContext();

const LanguagesProvider = ({ children, languages }) => (
  <LanguagesContext.Provider value={languages}>
    {children}
  </LanguagesContext.Provider>
);

const LanguagesConsumer = LanguagesContext.Consumer;

export { LanguagesProvider, LanguagesConsumer };
