import React from 'react';
import { Container, Img } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';
import Button from '../frontend/Button';

const SpotlightWidget = ({ variant, image, icon, title, description, button_1, button_2, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className={`spotlight ${variant === 'image-left' ? 'reverse' : ''}`}>
        <div className="content-wrapper">
          <div className="content">
            <div className="icon">
              <Img src={icon} variant="original" />
            </div>

            <h3 className="title">{title}</h3>
            {description && <div className="text" dangerouslySetInnerHTML={{ __html: description }} />}

            {(button_1.link && button_1.label) || (button_2.link && button_2.label) ?
              <div className="button-wrapper">
                {(button_1.link && button_1.label) && <Button settings={button_1} className="lg" />}
                {(button_2.link && button_2.label) && <Button settings={button_2} className="lg" />}
              </div>
              : null
            }
          </div>
        </div>

        <div className="image">
          <Img src={image} variant="fixed_image" />
        </div>
      </div>
    </div>
  </Container>
);

SpotlightWidget.title = 'Spotlight';
SpotlightWidget.category = 'Media';

SpotlightWidget.defaultProps = {
  icon: {},
  description: '',
  button_1: '',
  button_2: '',
};

SpotlightWidget.defaults = {
  image: 'http://placehold.it/800x600',
  icon: 'http://placehold.it/45x45',
  title: 'Spotlight title',
  description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro numquam harum repellendus provident mollitia dolorem minima. Impedit temporibus est, earum perspiciatis delectus doloremque quo debitis mollitia dolorum, quisquam maxime at!</p>',
  button_1: { label: 'Button', link: '#', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  button_2: { label: '', link: '', id: '', type: 'secondary', target: '_self', follow_link: 'follow' },
  variant: 'image-right',
  containerSettings: {},
};

SpotlightWidget.adminFields = {
  variant: { type: 'select', options: [['image-right', 'Image Right'], ['image-left', 'Image Left']] },
  image: { type: 'custom', component: ImagePicker },
  icon: { type: 'custom', component: ImagePicker },
  title: { type: 'text' },
  description: { type: 'custom', component: RichText },
  button_1: { type: 'button' },
  button_2: { type: 'button' },
};

export default SpotlightWidget;
