import React from 'react';
import { Container, imageSrc } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';
import Button from '../frontend/Button';

const LeadImageWidget = ({ overlay, image, mobileImage, title, description, button_1, button_2, height, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="lead-image" style={{ backgroundImage: `url(${imageSrc(image, 'lead')})` }}>
      <div className="mobile-image" style={{ backgroundImage: `url(${imageSrc(mobileImage, 'square')})` }}>
        <div className="container">
          <div className="lead-image-wrapper" style={{ height: `${height || '500px'}` }}>
            <div className="overlay" style={{ opacity: overlay }}></div>
            <div className="content">
              <h2>{title}</h2>
              {(description != '<p><br></p>') && <div className="text" dangerouslySetInnerHTML={{ __html: description }} />}

              {(button_1.link && button_1.label) || (button_2.link && button_2.label) ?
                <div className="button-wrapper">
                  {(button_1.link && button_1.label) && <Button settings={button_1} className="lg" />}
                  {(button_2.link && button_2.label) && <Button settings={button_2} className="lg" />}
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.title = 'Lead Image';
LeadImageWidget.category = 'Media';
LeadImageWidget.dialogSize = 'large';

LeadImageWidget.defaultProps = {
  description: '',
  button_1: {},
  button_2: {},
};

LeadImageWidget.defaults = {
  overlay: '0.2',
  image: 'http://placehold.it/1920x600',
  mobileImage: 'http://placehold.it/540x540',
  title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
  description: '<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
  button_1: { label: 'Try now', link: '#', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  button_2: { label: '', link: '', id: '', type: 'secondary', target: '_self', follow_link: 'follow' },
  height: '500px',
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  overlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%'], ['1', '100%']] },
  height: { type: 'text' },
  image: { type: 'custom', component: ImagePicker },
  mobileImage: { type: 'custom', component: ImagePicker },
  title: { type: 'text' },
  description: { type: 'custom', component: RichText },
  button_1: { type: 'button' },
  button_2: { type: 'button' },
};

export default LeadImageWidget;
