import React from 'react';
import Swiper from 'react-id-swiper';
import { Container, List, TextInput, Img, SelectMenu } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const LogoCarouselWidget = ({ list, autoplay, containerSettings }) => {
  const [swiper, updateSwiper] = React.useState(null);
  const swiperOptions = autoplay === 'yes' ? { autoplay: { delay: 3000 } } : {};

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container settings={containerSettings}>
      <div className="logos logo-carousel">
        <div className="container">
          <Swiper
            getSwiper={updateSwiper}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
            }}
            loop
            breakpoints={{
              939: {
                slidesPerView: 1,
                spaceBetween: 30,
                paginationClickable: true,
                slidesPerGroup: 1,
              },
              940: {
                slidesPerView: 6,
                spaceBetween: 30,
                paginationClickable: true,
                slidesPerGroup: 1,
              },
            }}
            {...swiperOptions}
          >
            {list.map((item, idx) =>
              <div className="slide" key={idx}>
                {item.link ?
                  <a href={item.link} className="logo" target="_blank"><Img src={item.image} variant="original" /></a>
                  :
                  <Img src={item.image} variant="original" className="logo" />
                }
              </div>
            )}
          </Swiper>

          <button onClick={goNext} className="swiper-button-next icon-arrow-right" />
          <button onClick={goPrev} className="swiper-button-prev icon-arrow-left" />
        </div>
      </div>
    </Container >
  );
}

LogoCarouselWidget.title = 'Logos Carousel';
LogoCarouselWidget.category = 'Media';
LogoCarouselWidget.dialogSize = 'large';

LogoCarouselWidget.defaultProps = {
  list: [],
  autoplay: 'no'
};

LogoCarouselWidget.defaults = {
  list: [
    { id: 1, image: 'http://placehold.it/300x300', link: '', },
    { id: 2, image: 'http://placehold.it/300x300', link: '', },
    { id: 3, image: 'http://placehold.it/300x300', link: '', },
    { id: 4, image: 'http://placehold.it/300x300', link: '', },
    { id: 5, image: 'http://placehold.it/300x300', link: '', },
    { id: 6, image: 'http://placehold.it/300x300', link: '', },
    { id: 7, image: 'http://placehold.it/300x300', link: '', },
    { id: 8, image: 'http://placehold.it/300x300', link: '', },
  ],
  variant: '3_columns',
  autoplay: 'no',
  containerSettings: {},
};

const LogoItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

LogoItem.defaultProps = {
  settings: {},
};

LogoCarouselWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Autoplay"
      options={[['yes', 'Yes'], ['no', 'No']]}
      value={settings.autoplay}
      onChange={value => onChange('autoplay', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <LogoItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
      primaryField="link"
      template={{ image: 'http://placehold.it/300x300', link: '', }}
    />
  </div>
);

export default LogoCarouselWidget;
