import React from 'react';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

const Layout = ({ children, page_context }) => (
  <>
    <SiteHeader
      menu={page_context.header_menu}
      i18n={page_context.i18n}
      languages={page_context.languages}
    />

    {children}

    <SiteFooter i18n={page_context.i18n} />
  </>
);

export default Layout;
