import SectionTitleWidget from './SectionTitleWidget';
import SingleImageWidget from './SingleImageWidget';
import TextBoxWidget from './TextBoxWidget';
import NavigationWidget from './NavigationWidget';
import HtmlWidget from './HtmlWidget';
import LeadImageWidget from './LeadImageWidget';
import CtaWidget from './CtaWidget';
import CtaAccentWidget from './CtaAccentWidget';
import IntroParagraphWidget from './IntroParagraphWidget';
import FeaturesWidget from './FeaturesWidget';
import SpotlightWidget from './SpotlightWidget';
import StepsWidget from './StepsWidget';
import StatsWidget from './StatsWidget';
import MosaicCardsWidget from './MosaicCardsWidget';
import CardsWidget from './CardsWidget';
import LeadVideoWidget from './LeadVideoWidget';
import LogosWidget from './LogosWidget';
import TestimonialWidget from './TestimonialWidget';
import HeroWidget from './HeroWidget';
import LogoCarouselWidget from './LogoCarouselWidget';
import ContactFormWidget from './ContactFormWidget/ContactFormWidget';
import TableWidget from './TableWidget';
import CardsCarouselWidget from './CardsCarouselWidget';
import ReferenceWidget from './ReferenceWidget';
import ReferenceSliderWidget from './ReferenceSliderWidget';
import GalleryWidget from './GalleryWidget';

export default {
  SectionTitleWidget,
  SingleImageWidget,
  TextBoxWidget,
  NavigationWidget,
  HtmlWidget,
  LeadImageWidget,
  CtaWidget,
  CtaAccentWidget,
  IntroParagraphWidget,
  FeaturesWidget,
  SpotlightWidget,
  StepsWidget,
  StatsWidget,
  MosaicCardsWidget,
  CardsWidget,
  LeadVideoWidget,
  LogosWidget,
  TestimonialWidget,
  HeroWidget,
  LogoCarouselWidget,
  ContactFormWidget,
  TableWidget,
  CardsCarouselWidget,
  ReferenceWidget,
  ReferenceSliderWidget,
  GalleryWidget,
};
