const CSRFToken = () => (
  document.querySelector('meta[name="csrf-token"]').content
)

const post = (url, params = {}, opts = {}) => fetch(
  url,
  Object.assign(
    {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(params),
      headers: {
        'X-CSRF-Token': CSRFToken(),
        'Content-Type': 'application/json'
      },
    },
    opts
  )
)

export default post;
