import React from 'react';
import { Container, imageSrc } from '@raketa-cms/raketa-cms';
import { RichText } from '@raketa-cms/raketa-rte';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const MosaicCard = ({ sm, title, description, image, theme, overlay }) => (
  <a href="#" className={`mosaic-card ${theme} ${sm && 'sm'}`} style={{ backgroundImage: `url(${imageSrc(image, 'lead')})` }}>
    <h3 className="title">{title}</h3>
    {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}

    <div className="overlay" style={{ backgroundImage: overlay ? `linear-gradient(-180deg, rgba(0,0,0,${overlay}) 0%, rgba(0,0,0,0.00) 100%)` : '' }}></div>
  </a>
);

const LargeCard = ({ largeCardTitle, largeCardDescription, largeCardImage, largeCardTheme, largeCardOverlay }) => (
  <div className="col-6">
    <MosaicCard
      title={largeCardTitle}
      description={largeCardDescription}
      image={largeCardImage}
      theme={largeCardTheme}
      overlay={largeCardOverlay}
    />
  </div>
);

const SmallCards = ({
  topCardTitle,
  topCardDescription,
  topCardTheme,
  topCardOverlay,
  topCardImage,
  bottomCardTitle,
  bottomCardDescription,
  bottomCardImage,
  bottomCardTheme,
  bottomCardOverlay,
}) => (
    <div className="col-6">
      <MosaicCard
        sm
        title={topCardTitle}
        description={topCardDescription}
        image={topCardImage}
        theme={topCardTheme}
        overlay={topCardOverlay}
      />

      <MosaicCard
        sm
        title={bottomCardTitle}
        description={bottomCardDescription}
        image={bottomCardImage}
        theme={bottomCardTheme}
        overlay={bottomCardOverlay}
      />
    </div>
  );

const MosaicCardsWidget = ({
  variant,
  largeCardTitle,
  largeCardImage,
  largeCardDescription,
  largeCardTheme,
  largeCardOverlay,
  topCardTitle,
  topCardImage,
  topCardDescription,
  topCardTheme,
  topCardOverlay,
  bottomCardTitle,
  bottomCardImage,
  bottomCardDescription,
  bottomCardTheme,
  bottomCardOverlay,
  containerSettings,
}) => (
    <Container settings={containerSettings}>
      <div className="mosaic">
        <div className="container">
          <div className="row">
            {variant === 'right' ?
              <React.Fragment>
                <SmallCards
                  topCardTitle={topCardTitle}
                  topCardDescription={topCardDescription}
                  topCardImage={topCardImage}
                  topCardTheme={topCardTheme}
                  topCardOverlay={topCardOverlay}
                  bottomCardTitle={bottomCardTitle}
                  bottomCardDescription={bottomCardDescription}
                  bottomCardTheme={bottomCardTheme}
                  bottomCardOverlay={bottomCardOverlay}
                  bottomCardImage={bottomCardImage}
                />

                <LargeCard
                  largeCardTitle={largeCardTitle}
                  largeCardDescription={largeCardDescription}
                  largeCardImage={largeCardImage}
                  largeCardTheme={largeCardTheme}
                  largeCardOverlay={largeCardOverlay}
                />
              </React.Fragment>
              :
              <React.Fragment>
                <LargeCard
                  largeCardTitle={largeCardTitle}
                  largeCardDescription={largeCardDescription}
                  largeCardImage={largeCardImage}
                  largeCardTheme={largeCardTheme}
                  largeCardOverlay={largeCardOverlay}
                />

                <SmallCards
                  topCardTitle={topCardTitle}
                  topCardDescription={topCardDescription}
                  topCardImage={topCardImage}
                  topCardTheme={topCardTheme}
                  topCardOverlay={topCardOverlay}
                  bottomCardTitle={bottomCardTitle}
                  bottomCardDescription={bottomCardDescription}
                  bottomCardTheme={bottomCardTheme}
                  bottomCardOverlay={bottomCardOverlay}
                  bottomCardImage={bottomCardImage}
                />
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </Container>
  );

MosaicCardsWidget.title = 'Mosaic Cards';
MosaicCardsWidget.category = 'Media';
MosaicCardsWidget.dialogSize = 'large';

MosaicCardsWidget.defaults = {
  variant: 'left',
  largeCardTitle: 'Card title',
  largeCardImage: 'http://placehold.it/600x800',
  largeCardDescription: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem dolore dignissimos consectetur autem. </p>',
  largeCardTheme: 'light',
  largeCardOverlay: '0.2',
  topCardTitle: 'Card title',
  topCardImage: 'http://placehold.it/600x800',
  topCardDescription: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem dolore dignissimos consectetur autem. </p>',
  topCardTheme: 'light',
  topCardOverlay: '0.2',
  bottomCardTitle: 'Card title',
  bottomCardImage: 'http://placehold.it/600x800',
  bottomCardDescription: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem dolore dignissimos consectetur autem. </p>',
  bottomCardTheme: 'light',
  bottomCardOverlay: '0.2',
  containerSettings: {},
};

MosaicCardsWidget.adminFields = {
  variant: { type: 'select', options: [['left', 'Left'], ['right', 'Right']] },
  largeCardTitle: { type: 'text' },
  largeCardImage: { type: 'custom', component: ImagePicker },
  largeCardDescription: { type: 'custom', component: RichText },
  largeCardTheme: { type: 'select', options: [['light', 'Light'], ['dark', 'Dark']] },
  largeCardOverlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%']] },
  topCardTitle: { type: 'text' },
  topCardImage: { type: 'custom', component: ImagePicker },
  topCardDescription: { type: 'custom', component: RichText },
  topCardTheme: { type: 'select', options: [['light', 'Light'], ['dark', 'Dark']] },
  topCardOverlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%']] },
  bottomCardTitle: { type: 'text' },
  bottomCardImage: { type: 'custom', component: ImagePicker },
  bottomCardDescription: { type: 'custom', component: RichText },
  bottomCardTheme: { type: 'select', options: [['light', 'Light'], ['dark', 'Dark']] },
  bottomCardOverlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%']] },
};

export default MosaicCardsWidget;
