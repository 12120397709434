import React from 'react';
import jQuery from 'jquery';
import { PageBuilder } from '@raketa-cms/raketa-cms';
import { MediaManager } from '@raketa-cms/raketa-image-picker';
import { LanguagesProvider } from './providers/LanguagesContext';
import Layout from './frontend/Layout';
import LIBRARY from './widgets';

const shouldRenderLayout = (page) => (page.slug !== 'contacts-modal');

const SPACINGS = [
  ['none', 'None'],

  // ['top-sm', 'Top (small)'],
  ['top', 'Top'],
  // ['top-lg', 'Top (large)'],

  // ['both-sm', 'Both (small)'],
  ['both', 'Both'],
  // ['both-lg', 'Both (large)'],

  // ['bottom-sm', 'Bottom (small)'],
  ['bottom', 'Bottom'],
  // ['bottom-lg', 'Bottom (large)'],
];


class AdminBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      isLoading: false,
      page: props.page,
    };

    this.mediaManager = new MediaManager('/images/client/');
  }

  componentDidMount() {
    // Check for changes and warn user
    jQuery(window).bind('beforeunload', () => {
      if (this.state.dirty) return 'You have unsaved changes';
    });
  }

  componentDidCatch(error, info) {
    console.log('error', error, info);
  }

  handleChange() {
    this.setState({ dirty: true });
  }

  handleSave(page) {
    const { save_url } = this.props;

    this.setState({ isLoading: true }, () => {
      jQuery.ajax({
        method: 'PATCH',
        beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
        contentType: 'application/json; charset=utf-8',
        url: save_url,
        data: JSON.stringify({ widgets: page.widgets }),
        dataType: 'json',
        success: (resp) => {
          this.setState({ isLoading: false, dirty: false });
        },
        error: (xhr, err, e) => {
          alert('An error occured saving this page. ');
        },
      });
    });
  }

  render() {
    const { host, back_url, page_context } = this.props;
    const { dirty, page } = this.state;

    const hasLayout = shouldRenderLayout(page);
    const LayoutComponent = hasLayout ? Layout : React.Fragment;

    return (
      <div className={`${hasLayout ? 'header-padding' : ''}`}>
        <LayoutComponent page_context={page_context}>
          <LanguagesProvider languages={page_context.languages}>
            <PageBuilder
              host={host}
              dirty={dirty}
              library={LIBRARY}
              spacings={SPACINGS}
              themes={[['none', 'None'], ['light-gray', 'Light'], ['dark', 'Dark']]}
              page={page}
              mediaManager={this.mediaManager}
              onChange={changedPage => this.handleChange(changedPage)}
              onSave={pageToSave => this.handleSave(pageToSave)}
              onExit={() => window.location.href = back_url}
              identifier="copycom"
            />
          </LanguagesProvider>
        </LayoutComponent>
      </div>
    );
  }
}

AdminBuilder.defaultProps = {
  host: 'http://localhost:3000/',
};

export default AdminBuilder;
