import React from 'react';
import { Container } from '@raketa-cms/raketa-cms';
import { RichText } from '@raketa-cms/raketa-rte';
import Button from '../frontend/Button';

const CtaWidget = ({ title, description, button_1, button_2, variant, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="cta">
        <div className="row">
          <div className={`${variant === 'centered' ? 'centered' : ''} col-8`}>
            <div className="content">
              {title && <h2>{title}</h2>}
              {(description != '<p><br></p>') && <div dangerouslySetInnerHTML={{ __html: description }} />}

              {(button_1.link && button_1.label) || (button_2.link && button_2.label) ?
                <div className="button-wrapper">
                  {(button_1.link && button_1.label) && <Button settings={button_1} className="lg" />}
                  {(button_2.link && button_2.label) && <Button settings={button_2} className="lg" />}
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CtaWidget.title = 'CTA';
CtaWidget.category = 'Media';

CtaWidget.defaultProps = {
  description: '',
  button_1: {},
  button_2: {},
};

CtaWidget.defaults = {
  title: 'Call to Action',
  description: '<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!</p>',
  button_1: { label: 'Read the case study', link: '#', id: '', type: 'secondary', target: '_self', follow_link: 'follow' },
  button_2: { label: '', link: '', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  variant: 'left',
  containerSettings: {},
};

CtaWidget.adminFields = {
  variant: { type: 'select', options: [['left', 'Left'], ['centered', 'Centered']] },
  title: { type: 'text' },
  description: { type: 'custom', component: RichText },
  button_1: { type: 'button' },
  button_2: { type: 'button' },
};

export default CtaWidget;