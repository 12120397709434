import React from 'react';
import { Container, List, TextInput, SelectMenu, Img } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';

const FeaturesWidget = ({ list, icons, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="features">
      <div className="container">
        <div className="row">
          {list.map((item, idx) =>
            <div className="col-4" key={idx}>
              <div className={`${icons === 'no' ? 'no-padding-left' : ''} feature`}>
                {icons === 'yes' ?
                  <div className="icon">
                    <Img src={item.icon} variant="original" />
                  </div>
                  : null
                }

                <div className="content">
                  <h4 className="title">{item.title}</h4>
                  <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </Container>
);

FeaturesWidget.defaultProps = {
  icons: 'yes',
};

FeaturesWidget.title = 'Features';
FeaturesWidget.category = 'Media';

FeaturesWidget.defaults = {
  icons: 'yes',
  list: [
    { id: 1, title: 'Feature name', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', icon: 'http://placehold.it/45x45' },
    { id: 2, title: 'Feature name', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', icon: 'http://placehold.it/45x45' },
    { id: 3, title: 'Feature name', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', icon: 'http://placehold.it/45x45' },
  ],
  containerSettings: {},
};

const FeaturesListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={value => onChangeItem('icon', value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

FeaturesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Icons"
      options={[['yes', 'Yes'], ['no', 'No']]}
      value={settings.icons}
      onChange={value => onChange('icons', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <FeaturesListItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{ title: 'Feature name', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', icon: 'http://placehold.it/45x45' }}
    />
  </div>
);

export default FeaturesWidget;
