import React from 'react';
import { Container, imageSrc } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';
import Button from '../frontend/Button';

const LeadVideoWidget = ({ video, overlay, image, title, description, button_1, button_2, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="light-bg">
      <div className="lead-video" style={{ backgroundImage: `url(${imageSrc(image, 'lead')})` }}>
        <video preload="auto" autoPlay muted loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="overlay" style={{ opacity: overlay }}></div>

        <div className="content">
          <div className="container">
            <h2>{title}</h2>
            {description && <div className="text" dangerouslySetInnerHTML={{ __html: description }} />}

            {(button_1.link && button_1.label) || (button_2.link && button_2.label) ?
              <div className="button-wrapper">
                {(button_1.link && button_1.label) && <Button settings={button_1} className="lg" />}
                {(button_2.link && button_2.label) && <Button settings={button_2} className="lg" />}
              </div>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadVideoWidget.title = 'Lead Video';
LeadVideoWidget.category = 'Media';

LeadVideoWidget.defaultProps = {
  description: '',
  button_1: {},
  button_2: {},
};

LeadVideoWidget.defaults = {
  overlay: '0.2',
  video: '',
  image: 'http://placehold.it/1920x600',
  title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
  description: '<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
  button_1: { label: 'Try now', link: '#', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  button_2: { label: 'Try now', link: '#', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  containerSettings: {},
};

LeadVideoWidget.adminFields = {
  overlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%']] },
  image: { type: 'custom', component: ImagePicker },
  video: { type: 'text' },
  title: { type: 'text' },
  description: { type: 'custom', component: RichText },
  button_1: { type: 'button' },
  button_2: { type: 'button' },
};

export default LeadVideoWidget;
