import React from 'react';
import { ImageInput, MediaManager } from '@raketa-cms/raketa-image-picker';

class ImagePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image || false,
    };
  }

  render() {
    const { name } = this.props;
    const { image } = this.state;

    return (
      <div>
        <ImageInput
          mediaManager={(new MediaManager('/images/client/'))}
          onChange={image => this.setState({ image })}
          {...this.props}
        />

        <input type="hidden" name={name} value={image ? JSON.stringify(image) : JSON.stringify({})} />
      </div>
    );
  }
};

export default ImagePicker;
