import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Container, Img, List, imageSrc } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const getLightboxImages = (list) => list.map(item => ({ src: imageSrc(item.image, 'lead') || '' }));

const GalleryWidget = ({ containerSettings, list }) => {
  const [state, setState] = useState({ open: false, index: 0 });

  const openLightbox = (index) => setState(state => ({ ...state, open: true, index }));
  const closeLightbox = () => setState(state => ({ ...state, open: false }));

  return (
    <Container settings={containerSettings} className="gallery">
      <div className="container">
        <Swiper
          rebuildOnUpdate
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          breakpoints={{
            601: {
              slidesPerView: 3,
              spaceBetween: 18,
              paginationClickable: true,
            },
            600: {
              slidesPerView: 1,
              spaceBetween: 0,
              paginationClickable: true,
            },
          }}
        >
          {list.map((slide, idx) =>
            <a key={slide.id} className="slide" onClick={() => openLightbox(idx)}>
              <Img src={slide.image} variant="card_horizontal" />
            </a>
          )}
        </Swiper>

        <ModalGateway>
          {state.open ? (
            <Modal onClose={closeLightbox}>
              <Carousel views={getLightboxImages(list)} currentIndex={state.index} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Container >
  );
};

GalleryWidget.title = 'Gallery';
GalleryWidget.category = 'Media';
GalleryWidget.dialogSize = 'large';

GalleryWidget.defaults = {
  list: [
    { id: 1, image: 'http://placehold.it/1920x1080' },
    { id: 2, image: 'http://placehold.it/1920x1080' },
    { id: 3, image: 'http://placehold.it/1920x1080' },
    { id: 4, image: 'http://placehold.it/1920x1080' },
  ],
  containerSettings: { theme: 'white' },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />
  </div>
);

GalleryWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <SlideItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      template={{ image: 'http://placehold.it/1920x1080' }}
      items={items}
    />
  </div>
);

export default GalleryWidget;
