import React from 'react';
import { Container, List, TextInput, SelectMenu, Img, imageSrc } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import Modal from '../frontend/Modal';
import { LanguagesConsumer } from '../providers/LanguagesContext';
import LanguageSwitcher from '../frontend/LanguageSwitcher';

class HeroWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  render() {
    const { logo, image, video, overlay, list, containerSettings, orderButtonLabel } = this.props;
    const { modalOpen } = this.state;

    return (
      <LanguagesConsumer>
        {(languages) => (
          <Container settings={containerSettings}>
            <div className="hero" style={{ backgroundImage: `url(${imageSrc(image, 'lead')})` }}>
              <LanguageSwitcher current={languages.current.label} all={languages.all} />

              <button type="button" className="floating-cta btn-secondary" onClick={() => this.setState({ modalOpen: true })}>{orderButtonLabel}</button>

              <video preload="auto" autoPlay muted loop>
                <source src={video} type="video/mp4" />
              </video>

              <div className="overlay" style={{ opacity: overlay }}></div>

              <div className="content">
                <div className="container">
                  <Img src={logo} variant="original" className="logo" />

                  <nav>
                    {list.map((item, idx) =>
                      <a href={item.link} key={item.id}>{item.label}</a>
                    )}
                  </nav>
                </div>
              </div>
            </div>
            <Modal title="Изпрати онлайн поръчка" className="modal-small" isOpen={modalOpen} onClose={() => this.setState({ modalOpen: false })}>
              {
                modalOpen &&
                <div className="modal-wrapper">
                  <iframe src={`/${languages.current.locale}/contacts-modal`} className="contacts-iframe" />
                </div>
              }
            </Modal>
          </Container>
        )}
      </LanguagesConsumer>
    );
  }
}

HeroWidget.title = 'Hero';
HeroWidget.category = 'Media';
HeroWidget.dialogSize = 'large';

HeroWidget.defaults = {
  logo: '',
  image: '',
  video: '',
  overlay: '0.2',
  orderButtonLabel: 'Онлайн поръчка',
  list: [
    { id: 1, label: 'Card title', link: '#' },
    { id: 2, label: 'Card title', link: '#' },
    { id: 3, label: 'Card title', link: '#' },
  ],
  containerSettings: {},
};

const NavigationListItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Label"
      onChange={value => onChangeItem('label', value)}
      value={settings.label}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

HeroWidget.adminFields = (items, onChange, settings) => (
  <div>
    <ImagePicker
      label="Logo"
      onChange={value => onChange('logo', value)}
      value={settings.logo}
    />

    <ImagePicker
      label="Mobile Background"
      onChange={value => onChange('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Video URL"
      onChange={value => onChange('video', value)}
      value={settings.video}
    />

    <TextInput
      label="Order Button Label"
      onChange={value => onChange('orderButtonLabel', value)}
      value={settings.orderButtonLabel}
    />

    <SelectMenu
      label="Overlay"
      options={[['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%']]}
      value={settings.overlay}
      onChange={value => onChange('overlay', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <NavigationListItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
      primaryField="label"
      template={{ label: 'Card title', link: '#' }}
    />
  </div>
);

export default HeroWidget;
