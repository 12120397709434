import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Card = ({ sm, title, link, image, description }) => (
  <a href={link} className="card">
    <div className="image">
      <Img src={image} variant="square" />
    </div>

    {sm ? (
      <h4 className="title">{title}</h4>
    ) : (
      <h3 className="title">{title}</h3>
    )}

    {description && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </a>
);

// const Card = ({ sm, title, link, image, description }) => (
//   <div className="card">
//     {link ?
//       <a href={link} className="image">
//         <Img src={image} variant="square" className="image-desktop" />
//         <Img src={image} variant="card_horizontal" className="image-mobile" />
//       </a>
//       :
//       (
//         <div className="image">
//           <Img src={image} variant="square" className="image-desktop" />
//           <Img src={image} variant="card_horizontal" className="image-mobile" />
//         </div>
//       )
//     }

//     {sm ?
//       <h4 className="title">{link ? <a href={link}>{title}</a> : title}</h4>
//       :
//       <h3 className="title">{link ? <a href={link}>{title}</a> : title}</h3>
//     }

//     {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
//   </div>
// );

const cardsVariant = {
  2: 6,
  3: 4,
  4: 3,
};

const CardsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cards">
      <div className="container">
        <div className="row">
          {list.map((card) => (
            <div key={card.id} className={`col-${cardsVariant[variant]}`}>
              <Card
                sm={variant == 4}
                title={card.title}
                link={card.link}
                image={card.image}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CardsWidget.title = "Cards";
CardsWidget.category = "Media";
CardsWidget.dialogSize = "large";

CardsWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "Card title",
      link: "#",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/540x540",
    },
    {
      id: 2,
      title: "Card title",
      link: "",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/540x540",
    },
    {
      id: 3,
      title: "Card title",
      link: "#",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/540x540",
    },
  ],
  containerSettings: {},
};

const CardsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

CardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <CardsListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "Card title",
        link: "#",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/540x540",
      }}
    />
  </div>
);

export default CardsWidget;
