import React from "react";
import Modal from "../frontend/Modal";
import LanguageSwitcher from "./LanguageSwitcher";

const SiteHeader = ({ menu, i18n, languages }) => {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <div className="site-header">
      <div className="container">
        <div className="mini-header">
          <div className="slogan">денонощен принт център</div>
          <div className="lang-switcher">
            <LanguageSwitcher
              current={languages.current.label}
              all={languages.all}
            />
          </div>
        </div>

        <div className="site-header-wrapper">
          <div className="logo-wrapper">
            <a href={`/${languages.current.locale}`} className="logo" />
          </div>

          <nav className="desktop-nav">
            {menu.items.map((item, idx) => (
              <a href={item.link.link} key={idx}>
                {item.link.label}
              </a>
            ))}
          </nav>

          <div className="desktop-actions">
            <button
              type="button"
              className="btn-primary"
              onClick={() => setModalOpen(true)}
            >
              {i18n.order_online}
            </button>
          </div>

          <div className={`mobile-nav ${open ? "show-nav" : ""}`}>
            <nav>
              {menu.items.map((item, idx) => (
                <a href={item.link.link} key={idx}>
                  {item.link.label}
                </a>
              ))}
            </nav>

            <div>
              <button
                type="button"
                className="btn-primary"
                onClick={() => setModalOpen(true)}
              >
                {i18n.order_online}
              </button>
            </div>
          </div>

          <div
            className={`menu-trigger ${open ? "close-trigger" : ""}`}
            onClick={() => setOpen(!open)}
          >
            <div className="trigger-item" />
            <div className="trigger-item" />
            <div className="trigger-item" />
          </div>
        </div>
      </div>

      <Modal
        title="Изпрати онлайн поръчка"
        className="modal-small"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        {modalOpen && (
          <div className="modal-wrapper">
            <iframe
              src={`/${languages.current.locale}/contacts-modal`}
              className="contacts-iframe"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SiteHeader;
