import React from 'react';
import { Container, Img } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';

const ReferenceWidget = ({ image, description, author, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="reference">
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <Img src={image} variant="square" className="avatar" />

            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />

            <strong>{author}</strong>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

ReferenceWidget.title = 'Reference';
ReferenceWidget.category = 'General';
ReferenceWidget.primaryField = 'author';
ReferenceWidget.dialogSize = 'large';

ReferenceWidget.defaults = {
  image: 'https://placehold.it/540x540',
  description: '<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
  author: 'John Doe, Director at ACME Inc.',
  containerSettings: {},
};

ReferenceWidget.adminFields = {
  image: { "type": "custom", "component": ImagePicker },
  description: { "type": "custom", "component": RichText },
  author: { "type": "text" },
};

export default ReferenceWidget;
