import React from 'react';
import { Container, List, TextInput, Img } from '@raketa-cms/raketa-cms';
import { RichText } from '@raketa-cms/raketa-rte';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';

const StepsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="steps">
      <div className="container">
        <div className="row">
          {list.map((item, idx) =>
            <div className="col-4" key={idx}>
              <div className="step">
                <Img src={item.image} variant="spotlight" />

                <h3><em>{idx + 1}.</em>{item.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </Container>
);

StepsWidget.title = 'Steps';
StepsWidget.category = 'Media';

StepsWidget.defaults = {
  list: [
    { id: 1, title: 'Step title', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', image: 'http://placehold.it/800x600' },
    { id: 2, title: 'Step title', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', image: 'http://placehold.it/800x600' },
    { id: 3, title: 'Step title', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', image: 'http://placehold.it/800x600' },
  ],
  containerSettings: {},
};

const StepsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

StepsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <StepsListItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{ title: 'Step title', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.', image: 'http://placehold.it/800x600' }}
    />
  </div>
);

export default StepsWidget;
