import React from 'react';
import { Container, imageSrc } from '@raketa-cms/raketa-cms';
import { ImagePicker } from '@raketa-cms/raketa-image-picker';
import { RichText } from '@raketa-cms/raketa-rte';
import Button from '../frontend/Button';

const CtaAccentWidget = ({ title, image, overlay, description, button_1, button_2, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cta with-background" style={{ backgroundImage: `url(${imageSrc(image, 'lead')})` }}>
      <div className="overlay" style={{ opacity: overlay }}></div>

      <div className="container">
        <div className="row">
          <div className="col-8">
            <div className="content">
              <h2>{title}</h2>
              {description && <div dangerouslySetInnerHTML={{ __html: description }} />}

              {(button_1.link && button_1.label) || (button_2.link && button_2.label) ?
                <div className="button-wrapper">
                  {(button_1.link && button_1.label) && <Button settings={button_1} className="lg" />}
                  {(button_2.link && button_2.label) && <Button settings={button_2} className="lg" />}
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CtaAccentWidget.title = 'CTA Accent';
CtaAccentWidget.category = 'Media';

CtaAccentWidget.defaultProps = {
  description: '',
  button_1: {},
  button_2: {},
};

CtaAccentWidget.defaults = {
  title: 'Call to Action',
  image: 'http://placehold.it/1920x600',
  overlay: '0.3',
  description: '<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!</p>',
  button_1: { label: 'Read the case study', link: '#', id: '', type: 'secondary', target: '_self', follow_link: 'follow' },
  button_2: { label: '', link: '', id: '', type: 'primary', target: '_self', follow_link: 'follow' },
  containerSettings: { theme: 'dark' },
};

CtaAccentWidget.adminFields = {
  image: { type: 'custom', component: ImagePicker },
  overlay: { type: 'select', options: [['0.1', '10%'], ['0.2', '20%'], ['0.3', '30%'], ['0.4', '40%'], ['0.5', '50%'], ['0.6', '60%'], ['0.7', '70%'], ['0.8', '80%'], ['0.9', '90%'], ['1', '100%']] },
  title: { type: 'text' },
  description: { type: 'custom', component: RichText },
  button_1: { type: 'button' },
  button_2: { type: 'button' },
};

export default CtaAccentWidget;
