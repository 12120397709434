import React from 'react';
import EmergencyNotice from './EmergencyNotice';

const Phone = () => (
  <div>
    <div className="mobile">
      <a href="tel:+359885108352" className="pulsating-circle">
        <span className="call-us">Contact us</span>
      </a>
    </div>

    <div className="desktop">
      <a href="/contacts" className="pulsating-circle">
        <span className="call-us">Contact us</span>
      </a>
    </div>
  </div>
);

const SiteFooter = ({ i18n }) => (
  <>
    <div className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="logo" />
            <p className="slogan">{i18n.slogan}</p>
          </div>
          <div className="col-4">
            <h4 className="contacts-title">Contacts</h4>

            <p>{i18n.address}</p>

            <ul>
              <li><strong>email:</strong> <a href="mailto:print@copycom.bg">print@copycom.bg</a></li>
              <li><strong>tel:</strong> <a href="tel:+35970030052">0700 300 52</a></li>
              <li><strong>mob:</strong> <a href="tel:+359885108352">+359 885 108 352</a></li>
            </ul>
          </div>
          <div className="col-4">
            <h4>Follow us</h4>

            <div className="social">
              <a href="https://www.facebook.com/copycombg/" className="icon-social icon-facebook" target="_blank" />
              <a href="https://www.linkedin.com/company/copycom-bulgaria/" className="icon-social icon-linkedin" target="_blank" />
            </div>

            <div className="newsletter-wrapper">
              <h4>Subscribe to our newsletter</h4>
              <form action="#" className="subscribe-form">
                <input type="text" className="form-control" placeholder="Your e-mail..." />
                <button className="btn-primary sm"><i className="icon chevron-right" /></button>
              </form>
            </div>
          </div>
        </div>

        <div className="fine-print">
          <div>&copy; 2018. Copycom</div>
          <div className="credits">
            launched by <a href="http://raketadesign.com/" target="_blank">raketa</a>
          </div>
        </div>
      </div>
    </div>

    {/* {(typeof window !== 'undefined') && (
      <EmergencyNotice />
    )} */}

    <Phone />
  </>
);

export default SiteFooter;
